import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import technology from "../../../asset/Images/banner/technology-1.jpg";
import Typewriter from 'typewriter-effect';
import "./technologySection.css";

const TechnologySection = () => {
  // Your array of image URLs
  const imageUrls = [
    "https://www.freecodecamp.org/news/content/images/2023/03/HTML-Blog-Cover-1.png",
    "https://austingil.com/wp-content/uploads/CSS%20Blog%20Cover.png",
    "https://upload.wikimedia.org/wikipedia/commons/thumb/9/96/Sass_Logo_Color.svg/800px-Sass_Logo_Color.svg.png",
    "https://codekitapp.com/images/help/free-bootstrap-icon@2x.png",
    "https://btihen.me/post_ruby_rails/rails_6_1_tailwind_2_0_alpinejs/featured.png",
    "https://miro.medium.com/v2/resize:fit:1400/0*WCfXH5nmHg-9LgIS.png",
    "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8e/Nextjs-logo.svg/2560px-Nextjs-logo.svg.png",
    "https://www.metaltoad.com/hubfs/Imported_Blog_Media/react-js-blog-header.png",
    "https://www.freecodecamp.org/news/content/images/2023/04/JavaScript-Blog-Cover.png",
    "https://upload.wikimedia.org/wikipedia/commons/thumb/3/37/Firebase_Logo.svg/2560px-Firebase_Logo.svg.png",
    "https://ajeetchaulagain.com/static/7cb4af597964b0911fe71cb2f8148d64/87351/express-js.png",
    "https://ubunlog.com/wp-content/uploads/2020/04/about-nodejs.png",
    "https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/MongoDB_Logo.svg/1200px-MongoDB_Logo.svg.png",
    "https://docs.convisoappsec.com/assets/images/github-1b6bd0e4b6626baba753847b04b62311.png",
    "https://ubiqum.com/assets/uploads/2021/12/learn-java-with-ubiqum-logo.png",
  ];

  const technologyData = imageUrls.map((imageUrl, index) => ({
    imageUrl,
  }));

  

  return (
    <div>
      <Container fluid>
        <section className="technology-section py-5 position-relative">
          <div className="container pb-5 mb-5">
            <Row className="mb-5">
              {/* Left Section with Image and Text */}
              <Col lg={6} md={12} className="mb-4">
                <img
                  src={technology}
                  alt="technology Section"
                  className="img-fluid rounded-lg"
                />
              </Col>

              {/* Right Heading, Paragraph, and Button */}
              <Col lg={6} md={12} className="techStyle p-3 text-start">
                {/* <h1 className="fs-5 text-start">TECHNOLOGY</h1> */}
                <h1 className="gradient-text">
                  <Typewriter
                    options={{
                      strings: ['OUR TECHNOLOGY PARTNERS'],
                      autoStart: true,
                      loop: true,
                    }}
                  />
                </h1>
                <p className="text-muted mb-5">
                  Our clients consistently praise our commitment to quality and
                  service. Join the growing list of satisfied clients who trust
                  us for their meeting needs.
                </p>
                <Link to="/about" ><button className="buttonStyle">Explore</button></Link>
              </Col>
            </Row>

            {/* Technology Carousel */}
            <div className="technology-carousel-wrapper">
              <div className="technology-carousel">
                {technologyData.map((tech, index) => (
                  <div
                    key={index}
                    className="card bg-white technology-card border-0 mx-3 p-3 shadow-sm"
                  >
                    <div className="d-flex align-items-center justify-content-center">
                      <img
                        src={tech.imageUrl}
                        alt=""
                        className="w-full"
                        style={{ width: "100px", height: "100px" }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </Container>
    </div>
  );
};

export default TechnologySection;
