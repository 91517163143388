import React from 'react';
import Navbar from '../../Shared/Navbar';
import Footer from '../../Shared/Footer';

const AppDevelopment = () => {
    return (
        <div>
            <Navbar></Navbar>

            hi app development
            <Footer></Footer>
        </div>
    );
};

export default AppDevelopment;