import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../asset/Images/Logo/avinil-logo-final.png';
import './sharedCss/footer.css'
const Footer = () => {
    return (
        <div className="footer-container ">
            {/* Footer Start */}
            <div className="footer_style container">
                <div className="footer-section">
                    {/* Logo Section */}
                    <img className="footer-logo" src={logo} alt="Avinil IT Logo" />
                    <h5 className="footer-text">
                        A Software Development & Information Technology Company
                    </h5>
                </div>

                <div className="footer-section">
                    <h4 className="footer-title">Quick Links</h4>
                    <Link to="/about" className="footer-link">About Us</Link>
                    <Link to="/contact" className="footer-link">Contact Us</Link>
                    <Link to="/privacyPolicy" className="footer-link">Privacy Policy</Link>
                    <Link to="/termsCondition" className="footer-link">Terms & Condition</Link>
                    <Link to="/faqs" className="footer-link">FAQs & Help</Link>
                </div>

                <div className="footer-section">
                    <h4 className="footer-title">Contact</h4>
                    <p className="footer-contact"><i className="fa fa-map-marker-alt"></i> Chattogram, Bangladesh</p>
                    <p className="footer-contact"><i className="fa fa-envelope"></i> avinil.it.ltd@gmail.com</p>
                    <div className="footer-socials">
                        <Link target="_blank" to="https://www.facebook.com/profile.php?id=61550537558799" className="social-icon facebook">
                            <i className="fab fa-facebook-f"></i>
                        </Link>
                        <Link target="_blank" to="https://www.linkedin.com/in/avinil-it-ltd-822162295/" className="social-icon linkedin">
                            <i className="fab fa-linkedin-in"></i>
                        </Link>
                    </div>
                </div>

                <div className="footer-section">
                    <h4 className="footer-title">Subscribe Now</h4>
                    <p>Complete the subscription for our Update news and Your future Career.</p>
                    <div className="footer-subscribe">
                        <input className="subscribe-input" type="text" placeholder="Your email" />
                        <button type="button" className="subscribe-button">Subscribe</button>
                    </div>
                </div>

            </div>

            <div className="footer-bottom   w-100">
                <p className='mx-auto text-center'>&copy; <Link to="/home" className="footer-home-link">Avinil IT LTD</Link>, All Rights Reserved.</p>
            </div>




        </div>
    );
};

export default Footer;
