import React from 'react';
import Navbar from '../../Shared/Navbar';
import Footer from '../../Shared/Footer';

const DigitalMarketing = () => {
    return (
        <div>
            <Navbar></Navbar>
            hi digital marketing
            <Footer></Footer>
        </div>
    );
};

export default DigitalMarketing;