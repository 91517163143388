import React from "react";
import "./ourClient.css"; // Import the CSS file

const OurClient = () => {
  // Sample client logos
  const clientLogos = [
    { id: 1, src: "https://static.cdnlogo.com/logos/t/8/tata-motors.png", alt: "Client 1" },
    { id: 2, src: "https://www.armcommunications.com/ckfinder/userfiles/images/25%20top/sbi.png", alt: "Client 2" },
    { id: 3, src: "https://1000logos.net/wp-content/uploads/2018/03/SBI-Logo-tumb.png", alt: "Client 3" },
    { id: 4, src: "https://www.armcommunications.com/ckfinder/userfiles/images/25%20top/wipro.png", alt: "Client 4" },
    { id: 5, src: "https://i.pinimg.com/236x/50/a3/30/50a3300a69a61a1af350f03922392c9f.jpg", alt: "Client 5" },
    { id: 6, src: "https://seekvectorlogo.net/wp-content/uploads/2018/10/indian-company-info-vector-logo.png", alt: "Client 5" },
    { id: 7, src: "https://www.armcommunications.com/ckfinder/userfiles/images/25%20top/25a/Hero.png", alt: "Client 5" },
    { id: 8, src: "https://upload.wikimedia.org/wikinews/en/thumb/0/0c/Google_logo_png.png/800px-Google_logo_png.png", alt: "Client 5" },
    { id: 9, src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-_vzDtgpWeTntrkJQlCdIBAukT4WrHRJsyw&s", alt: "Client 5" },
    { id: 10, src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-_vzDtgpWeTntrkJQlCdIBAukT4WrHRJsyw&s", alt: "Client 5" },
    // Add more logos as needed
  ];

  return (
    <div className="our-client-section container">
      <h2 className="section-title">Our Clients & Partners</h2>
      <div className="client-logos-container">
        {clientLogos.map((logo) => (
          <div className="client-card" key={logo.id}>
            <img className="client-logo" src={logo.src} alt={logo.alt} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurClient;
