import React from 'react';
import Navbar from '../../Shared/Navbar';
import Footer from '../../Shared/Footer';

const GraphicDesign = () => {
    return (
        <div>
            <Navbar></Navbar>
            hi graphich design
            <Footer></Footer>
        </div>
    );
};

export default GraphicDesign;