import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.css';
import React, { useState, useEffect } from 'react';
import Home from './Component/Home/Home';
import Contact from './Component/Contact/Contact';
import About from './Component/About/About';
import Career from './Component/Career/Career';
import NotFound from './Component/Shared/NotFound';
import TermsCondition from './Component/TermsCondition/TermsCondition';
import PrivacyPolicy from './Component/PrivacyPolicy/PrivacyPolicy';
import Services from './Component/Services/Services';
import Dashboard from './Component/Dashboard/Dashboard';
import Overview from './Component/Dashboard/Overview';
import AddTeamMember from './Component/Dashboard/AddTeamMember';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import CareerApplicationForm from './Component/Career/CareerApplicationForm';
import WebDevelopement from './Component/Services/WebDevelopement/WebDevelopement';
import AppDevelopment from './Component/Services/AppDevelopment/AppDevelopment';
import ErpSolution from './Component/Services/ErpSolution/ErpSolution';
import DigitalMarketing from './Component/Services/DigitalMarketing/DigitalMarketing';
import ECommerceSolution from './Component/Services/ECommerceSolution/ECommerceSolution';
import GraphicDesign from './Component/Services/GraphicDesign/GraphicDesign';


function App() {
  const router = createBrowserRouter([
    { path: "/", element: <Home></Home> },
    { path: "home", element: <Home></Home> },
    { path: "contact", element: <Contact></Contact> },



    { path: "services", element: <Services></Services> },
    { path: "services/web-development", element: <WebDevelopement></WebDevelopement> },
    { path: "services/app-development", element: <AppDevelopment></AppDevelopment> },
    { path: "services/erp-solution", element: <ErpSolution></ErpSolution> },
    { path: "services/digital-marketing", element: <DigitalMarketing></DigitalMarketing> },
    { path: "services/e-commerce", element: <ECommerceSolution></ECommerceSolution> },
    { path: "services/graphic-design", element: <GraphicDesign></GraphicDesign> },



    { path: "career", element: <Career></Career> },
    { path: "about", element: <About></About> },
    { path: "*", element: <NotFound></NotFound> },
    { path: "termsCondition", element: <TermsCondition></TermsCondition> },
    { path: "privacyPolicy", element: <PrivacyPolicy></PrivacyPolicy> },
    { path: "applyCareer", element: <CareerApplicationForm></CareerApplicationForm> },




    {
      path: "dashboard",
      element: <Dashboard></Dashboard>,
      children: [
        { path: "", element: <Overview></Overview> },
        { path: "addTeamMember", element: <AddTeamMember></AddTeamMember> },
        // { path: "addClientReview", element: <AddClientReview></AddClientReview> }, 
        // { path: "updateProject", element: <ProjectListForUpdate /> }, 
        // { path: "updateProject/:id", element: <UpdateProject /> }, 
        // { path: "changeImage", element: <ChangeImage /> }, 
        // { path: "changeCredentials", element: <ChangeCredentials /> }, 
        // { path: "projects", element: <ProjectList /> }, 
        // { path: "addProject", element: <AddProject /> }, 
        // { path: "contactDashboard", element: <ContactDashboard /> }, 
        // { path: "faqDashboard", element: <FAQDashboard /> }, 
        // { path: "testimonialDashboard", element: <TestimonialDashboard /> }, 
        // { path: "careers", element: <CareerList /> }, 
        // { path: "addCareer", element: <AddCareer /> }, 
        // { path: "updateCareer/:id", element: <UpdateCareer /> }, 
        // { path: "applications", element: <ApplicationList /> }, 
        // { path: "categories", element: <CategoryManagement /> }, 
        // { path: "UpdateAboutDetails", element: <ChangeAboutDetails /> }, 
        // { path: "changePrivacyPolicy", element: <PolicyDashboard /> }, 
        // { path: "UpdateContactDetails", element: <UpdateContactDetails /> }, 
        // { path: "settings", element: <SettingsPage /> }, 
      ],
    }





  ])

  return (
    <div className="">
      <RouterProvider router={router}></RouterProvider>
    </div>
  );
}

export default App;




// import { RouterProvider, createBrowserRouter } from 'react-router-dom';
// import './App.css';
// import Home from './Component/Home/Home';
// import Contact from './Component/Contact/Contact';
// import About from './Component/About/About';
// import Career from './Component/Career/Career';
// import NotFound from './Component/Shared/NotFound';
// import TermsCondition from './Component/TermsCondition/TermsCondition';
// import PrivacyPolicy from './Component/PrivacyPolicy/PrivacyPolicy';
// import Services from './Component/Services/Services';
// import Dashboard from './Component/Dashboard/Dashboard';
// import Overview from './Component/Dashboard/Overview';
// import AddTeamMember from './Component/Dashboard/AddTeamMember';

// function App() {
//   const router = createBrowserRouter([
//     { path: "/", element: <Home /> },
//     { path: "home", element: <Home /> },
//     { path: "contact", element: <Contact /> },
//     { path: "services", element: <Services /> },
//     { path: "career", element: <Career /> },
//     { path: "about", element: <About /> },
//     { path: "*", element: <NotFound /> },
//     { path: "termsCondition", element: <TermsCondition /> },
//     { path: "privacyPolicy", element: <PrivacyPolicy /> },

//     // Dashboard parent route with children
//     {
//       path: "dashboard",
//       element: <Dashboard />,  // Always render Dashboard as parent layout
//       children: [
//         { index: true, element: <Overview /> },  // Default child (Overview)
//         { path: "addTeamMember", element: <AddTeamMember /> },  // Child route for AddTeamMember
//       ],
//     },
//   ]);

//   return (
//     <div>
//       <RouterProvider router={router}></RouterProvider>
//     </div>
//   );
// }

// export default App;
