import React from 'react';
import profile from "../../asset/Images/dashboard/profile.png";
const RecentCustomers = () => {
    return (
        <div>
            <div class="recentCustomers">
              <div class="cardHeader pb-3 d-flex justify-content-between align-items-center ">
                <h4 className="text-primary fs-5">Recent Customers</h4>
                <button className='btn btn-sm btn-danger'>view all</button>
              </div>
              <table className="">
                <tr>
                  <td className="pe-2">
                    <img Style="width:30px" src={profile} alt="" />
                  </td>
                  <td className="pe-2 fs-6 fw-bold">Ajoy Barua</td>
                  <td className="fs-6 ">01822114359</td>
                </tr>
              </table>
            </div>
        </div>
    );
};

export default RecentCustomers;