import React from "react";
import "./whychooseus.css";

const WhyChoose = () => {
  return (
    <section className="why-choose-section py-5">
      <div className="container">
        <div className="row align-items-center">
          {/* Left Column: Images */}
          <div className="col-lg-6">
            <div className="image-grid position-relative">
              {/* Image 1 */}
              <div className="image-large">
                <img
                  src="https://demo.egenslab.com/html/creasoft/preview/assets/img/why-1.jpg"
                  alt="Team collaboration"
                  className="img-fluid rounded shadow-sm fixed-height"
                />
              </div>

              {/* Images 2 and 3 */}
              <div className="image-small-stack d-flex flex-column gap-3">
                <div>
                  <img
                    src="https://demo.egenslab.com/html/creasoft/preview/assets/img/why-2.jpg"
                    alt="Team success"
                    className="img-fluid rounded shadow-sm"
                  />
                </div>
                <div className="position-relative">
                  {/* Badge */}
                  <div className="years-badge position-absolute">
                    <div className="badge-content text-center">
                      <h4 className="text-light m-0 fw-bold">20+</h4>
                      <p className="text-light mb-0 small">YEARS</p>
                    </div>
                  </div>
                  <img
                    src="https://demo.egenslab.com/html/creasoft/preview/assets/img/why-3.jpg"
                    alt="Creative work"
                    className="img-fluid rounded shadow-sm"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Right Column: Content */}
          <div className="col-lg-6">
            <div className="content-box p-4 rounded bg-white">
              <h6 className="text-uppercase fw-bold purple-text">
                Why Choose
              </h6>
              <h2 className="fw-bold mb-4">
                Phasellus Tristique A Nisi Etal Tristique Cras Interdum.
              </h2>
              <div className="d-flex justify-content-between text-center mb-4">
                <div>
                  <h3 className="fw-bold purple-text">150+</h3>
                  <p className="mb-0">Project Completed</p>
                </div>
                <div>
                  <h3 className="fw-bold purple-text">250+</h3>
                  <p className="mb-0">Satisfied Clients</p>
                </div>
                <div>
                  <h3 className="fw-bold purple-text">50+</h3>
                  <p className="mb-0">Expert Teams</p>
                </div>
              </div>
              <p>
                Integer purus odio, placerat nec rhoncus in, ullamcorper nec
                dolor. Class online aptent taciti sociosqu ad litora torquent
                per conubia nostra, per inceptos himenaeos.
              </p>
              <div className="d-flex gap-3">
                <button className="btn btn-purple px-4">24/7 Support</button>
                <button className="btn btn-purple px-4">Unique Design</button>
                <button className="btn btn-purple px-4">
                  Clean Code Develop
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChoose;
