import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './career.css'
const JobOpenings = () => {


    const [careers, setCareers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null); // State for error handling

    useEffect(() => {
        const fetchCareers = async () => {
            try {
                const response = await fetch('https://3pcommunicationsserver.vercel.app/api/careers');
                const data = await response.json();

                if (response.ok) {
                    setCareers(data); // Access careers from the response
                } else {
                    throw new Error(data.message || 'Failed to fetch careers.');
                }
            } catch (error) {
                console.error('Error fetching careers:', error);
                setError('Failed to fetch job openings. Please try again later.');
            } finally {
                setLoading(false); // Stop loading regardless of success or failure
            }
        };

        fetchCareers();
    }, []);

    const filteredCareers = careers.filter(
        (career) =>
            career.status && // Check if the career is active
            career.title.toLowerCase().includes(searchTerm.toLowerCase())
    );







    return (
        <div>
            <div className="container my-5">
                {/* Search Input */}
                <div className="input-group mb-4 mt-5 d-flex justify-content-between">
                    <h2 className="mb-4" style={{
                        background: "linear-gradient(90deg, #941BFE, #977EFE)",
                        WebkitBackgroundClip: "text",
                        color: "transparent"
                    }}>
                        Job Openings
                    </h2>

                    <div className="d-flex">
                        <input
                            type="text"
                            placeholder="Search for a job..."
                            value={searchTerm}
                            style={{
                                border: "2px solid purple",  // Purple border
                                outline: "none"  // Removes outline on focus
                            }}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="form-control py-0 my-0"
                            aria-label="Search for a job"
                        />

                        <button
                            className="btn buttonStyle fw-bold px-4 py-0 ms-2"
                            type="button"
                        >
                            Search
                        </button>
                    </div>
                </div>

                <hr />

                {/* Career Cards */}
                {filteredCareers.length > 0 ? (
                    <div className="row">
                        {filteredCareers.map((career) => (
                            <div key={career._id} className="col-12 my-4">
                                <div className="card shadow-sm border-0">
                                    <div className="card-body shadow-sm p-4 d-flex justify-content-between align-items-center">
                                        <div className="w-100">
                                            <h5 className="card-title fs-3 mb-4" style={{
                                                background: "linear-gradient(90deg, #941BFE, #977EFE)",
                                                WebkitBackgroundClip: "text",
                                                color: "transparent"
                                            }}>
                                                {career.title}
                                            </h5>
                                            <div className="row my-2 w-full">
                                                {/* Left Column: Vacancy and Salary */}
                                                <div className="col-md-4 mb-2">
                                                    <p className="mb-1">
                                                        <strong>Vacancy:</strong>{" "}
                                                        {career.vacancy || "Not specified"}
                                                    </p>
                                                    <p className="mb-1">
                                                        <strong>Experience:</strong>{" "}
                                                        {career.experience || "Not specified"}
                                                    </p>
                                                </div>

                                                {/* center Column: Location and Experience */}
                                                <div className="col-md-4 mb-2">
                                                    <p className="mb-1">
                                                        <strong>Location:</strong> {career.location}
                                                    </p>
                                                    <p className="mb-1">
                                                        <strong>Salary:</strong>{" "}
                                                        {career.salary || "Negotiable"}
                                                    </p>
                                                </div>

                                                {/* right Column: Location and Experience */}
                                                <div className="col-md-4 mb-2 text-center">
                                                    <Link
                                                        to={`/applyCareer`}
                                                        className="btn  buttonStyle  px-4"
                                                        style={{

                                                        }}
                                                    >
                                                        View Details
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="text-center mt-5">
                        <h5>No job openings found. Please check back later!</h5>
                    </div>
                )}
            </div>
        </div>
    );
};

export default JobOpenings;