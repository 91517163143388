// import './faq.css';
// import React, { useState } from 'react';
// import faqImage from '../../../asset/Images/banner/faq2.png';

// const Faq = () => {
//   const [activeIndex, setActiveIndex] = useState(null);

//   const handleToggle = (index) => {
//     setActiveIndex(activeIndex === index ? null : index);
//   };

//   return (
//     <div className="faq-section container">
//       <div className="row py-5 px-3">
//         {/* FAQ Content */}
//         <div className="col-lg-6 col-md-12" data-aos="">
//           <h2 className="">
//             FREQUENTLY <span className="highlight">ASKED</span> QUESTION
//           </h2>

//           <div className="accordion">
//             {/* FAQ Items */}
//             {faqData.map((item, index) => (
//               <div className="faq-item" key={index}>
//                 <button
//                   className={`faq-question ${activeIndex === index ? 'active' : ''}`}
//                   onClick={() => handleToggle(index)}
//                 >
//                   {item.question}
//                   <span className="icon">{activeIndex === index ? '-' : '+'}</span>
//                 </button>
//                 <div
//                   className={`faq-answer ${activeIndex === index ? 'show' : ''}`}
//                   style={activeIndex === index ? { height: 'auto' } : { height: '0px' }}
//                 >
//                   <p>{item.answer}</p>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>

//         {/* FAQ Image */}
//         <div className="col-lg-6 col-md-12 faq-image" data-aos="">
//           <img className="img-fluid mx-auto" src={faqImage} alt="FAQ" />
//         </div>
//       </div>
//     </div>
//   );
// };

// // FAQ data
// const faqData = [
//   {
//     question: 'What services do you offer?',
//     answer: 'We offer a range of services including custom software development, web development, IT solutions, and digital marketing.'
//   },
//   {
//     question: 'How can I get started with a project?',
//     answer: 'Getting started is easy! Simply contact us through our website or give us a call. We’ll set up an initial consultation to discuss your project requirements.'
//   },
//   {
//     question: 'What is your development process?',
//     answer: 'Our development process includes consultation, requirement analysis, design, development, testing, and deployment, with ongoing updates and communication.'
//   },
//   {
//     question: 'Do you offer post-launch support?',
//     answer: 'Yes, we provide comprehensive post-launch support including maintenance, updates, and troubleshooting as needed.'
//   },
//   {
//     question: 'What industries do you specialize in?',
//     answer: 'We specialize in healthcare, finance, education, retail, and more. Our team understands the challenges of each industry and delivers tailored solutions.'
//   }
// ];

// export default Faq;
import './faq.css';
import React, { useEffect, useState } from 'react';
import faqImage from '../../../asset/Images/faq.png';
// import faqImage from '../../../asset/Images/banner/faq2.png';

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };



  const fullText = "FREQUENTLY ASKED QUESTION"; // Constant string
  const [displayText, setDisplayText] = useState("");
  const [charIndex, setCharIndex] = useState(0);

  useEffect(() => {
      if (charIndex < fullText.length) {
          const timeout = setTimeout(() => {
              setDisplayText((prev) => prev + fullText[charIndex]);
              setCharIndex((prevIndex) => prevIndex + 1);
          }, 200); // Adjust typing speed here
          return () => clearTimeout(timeout);
      }
  }, [charIndex]);



  return (
    <div className="faq-section container">
      <div className="faq-wrapper row">
        {/* Left Side: FAQ Section */}
        <div className="faq-content col-lg-6 col-md-12">
          <h2 className="gradient-text">{displayText}          </h2>
          <div className="accordion">
            {faqData.map((item, index) => (
              <div className="faq-item" key={index}>
                <button
                  className={`faq-question ${activeIndex === index ? 'active' : ''}`}
                  onClick={() => handleToggle(index)}
                >
                  {item.question}
                  <span className="icon">{activeIndex === index ? '-' : '+ '}</span>
                </button>
                <div
                  className={`faq-answer ${activeIndex === index ? 'show' : ''}`}
                  style={activeIndex === index ? { height: 'auto' } : { height: '0px' }}
                >
                  <p>{item.answer}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Right Side: Image */}
        <div className="faq-image col-lg-6 col-md-12">
          <img className="img-fluid mx-auto" src={faqImage} alt="FAQ Illustration" />
        </div>
      </div>
    </div>
  );
};

// FAQ Data
const faqData = [
  {
    question: 'What services do you offer?',
    answer: 'We offer a range of services including custom software development, web development, IT solutions, and digital marketing.'
  },
  {
    question: 'How can I get started with a project?',
    answer: 'Getting started is easy! Simply contact us through our website or give us a call. We’ll set up an initial consultation to discuss your project requirements.'
  },
  {
    question: 'What is your development process?',
    answer: 'Our development process includes consultation, requirement analysis, design, development, testing, and deployment, with ongoing updates and communication.'
  },
  {
    question: 'Do you offer post-launch support?',
    answer: 'Yes, we provide comprehensive post-launch support including maintenance, updates, and troubleshooting as needed.'
  },
  {
    question: 'What industries do you specialize in?',
    answer: 'We specialize in healthcare, finance, education, retail, and more. Our team understands the challenges of each industry and delivers tailored solutions.'
  }
];

export default Faq;
