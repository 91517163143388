import React, { useState, useEffect } from "react";
import Navbar from "../Shared/Navbar";
import Footer from "../Shared/Footer";
import Loading from "../Shared/Loading"; // Import the Loading component
import Banner from "./Banner/Banner";
import MainServices from "./MainServices/MainServices";
import Faq from "./Faq/Faq";
// import Testimonials from "./Testimonials/Testimonials";
import TechnologySection from "./TechnologySection/TechnologySection";
import OurClient from "./OurClient/OurClient";
import StatsSection from "./StatsSection/StatsSection";

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);

  // Simulate loading effect
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Loader will disappear after 2 seconds
    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <Loading />; // Show loader if still loading
  }
// linear-gradient(90deg, #941BFE, #977EFE);
  return (
    <div style={{ backgroundColor: "#f3f8ff" }}>
      <Navbar></Navbar>
      <Banner></Banner>
      <MainServices></MainServices>
      <StatsSection></StatsSection>
      <OurClient />
      <TechnologySection />
      {/* <Testimonials /> */}
      <Faq />
      <Footer />
    </div>
  );
};

export default Home;
