import React, { useState, useEffect } from 'react';
import './mainServices.css';
import { MdOutlineIntegrationInstructions, MdOutlineWeb } from 'react-icons/md'; // Material Design
import { FaBullhorn, FaEnvelope, FaSearch } from 'react-icons/fa'; // FontAwesome
import { AiOutlineCloudServer } from 'react-icons/ai'; // Ant Design icons
import Typewriter from 'typewriter-effect';
const ProvidedServices = () => {

  // const fullText = "Feel The Power Of Technology Software Service"; // Constant string
  // const [displayText, setDisplayText] = useState("");
  // const [charIndex, setCharIndex] = useState(0);

  // useEffect(() => {
  //   if (charIndex < fullText.length) {
  //     const timeout = setTimeout(() => {
  //       setDisplayText((prev) => prev + fullText[charIndex]);
  //       setCharIndex((prevIndex) => prevIndex + 1);
  //     }, 200); // Adjust typing speed here
  //     return () => clearTimeout(timeout);
  //   }
  // }, [charIndex]);


  const services = [
    {
      id: 1,
      title: "App Integration",
      description: "We provide the best software services for any type of business as strategy, management.",
      icon: <MdOutlineIntegrationInstructions size={50} />
    },
    {
      id: 2,
      title: "Social Marketing",
      description: "We provide the best software services for any type of business as strategy, management.",
      icon: <FaBullhorn size={50} />
    },
    {
      id: 3,
      title: "Web Application Development",
      description: "We provide the best software services for any type of business as strategy, management.",
      icon: <MdOutlineWeb size={50} />
    },
    {
      id: 4,
      title: "ERP Solution",
      description: "We provide the best software services for any type of business as strategy, management.",
      icon: <AiOutlineCloudServer size={50} />
    },
    {
      id: 5,
      title: "SEO Optimization",
      description: "We provide the best software services for any type of business as strategy, management.",
      icon: <FaSearch size={50} />
    },
    {
      id: 6,
      title: "Email Marketing",
      description: "We provide the best software services for any type of business as strategy, management.",
      icon: <FaEnvelope size={50} />
    }
  ];


  return (
    <div className='services-section'>
      <div className="mainServicesStyle container">

        <div className='section-title'>
          {/* <p className='text-sm text-dark '>SERVICES</p> */}
          {/* <p className='para' style={{ fontSize: "14px", color: "black", marginBottom: "5px" }}>SERVICES</p> */}
          {/* <h1 className='gradient-text'>{displayText}</h1> */}
          <h1 className="gradient-text">
            <Typewriter
              options={{
                strings: ['Feel The Power Of Technology Software Service'],
                autoStart: true,
                loop: true,
              }}
            />
          </h1>
        </div>

        {services.map(service => (
          <div key={service.id} className="service-card">
            <div className="icon">
              {service.icon}
            </div>
            <h3>{service.title}</h3>
            <p>{service.description}</p>
            {/* Add a Read More link or any additional functionality here */}
            {/* <a href="#" className="read-more">Read More →</a> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProvidedServices;
