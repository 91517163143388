// import React from 'react';
// import { Nav } from 'react-bootstrap';
// import { Link, useLocation } from 'react-router-dom';
// import {

//     FaTachometerAlt,

// } from 'react-icons/fa';

// import './css/dashboard.css';

// const Sidebar = () => {
//     const location = useLocation();

//     // Helper function to apply active style if the link is the current path
//     const isActive = (path) => location.pathname === path;

//     return (
//         <div className="d-flex flex-column sidebar">
//             <Nav className="flex-column p-3">
//                 <h5 className="mt-4">Dashboard</h5>
//                 <Nav.Link
//                     as={Link}
//                     to="/dashboard"
//                     className={`mb-2 ${isActive('/dashboard') ? 'active-link' : ''}`}
//                 >
//                     <FaTachometerAlt /> Overall Dashboard
//                 </Nav.Link>
//                {/* <Nav.Link
//                     as={Link}
//                     to="/dashboard/categories"
//                     className={`mb-2 ${isActive('/dashboard/categories') ? 'active-link' : ''}`}
//                 >
//                     <FaPlusCircle /> Services Categories
//                 </Nav.Link> */}
                
//             </Nav>
//         </div>
//     );
// };

// export default Sidebar;



import React from 'react';
import { Nav } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { FaTachometerAlt } from 'react-icons/fa';
import './css/dashboard.css';  // Ensure this has the required CSS

const Sidebar = () => {
    const location = useLocation();

    // Helper function to apply active style if the link is the current path
    const isActive = (path) => location.pathname === path;

    return (
        <div className="sidebar-container p-3">
            <h5 className="sidebar-heading mt-4">Dashboard</h5>
            <Nav className="flex-column">
                <Nav.Link
                    as={Link}
                    to="/dashboard"
                    className={`mb-2 sidebar-link ${isActive('/dashboard') ? 'active-link' : ''}`}
                >
                    <FaTachometerAlt className="me-2" /> Overall Dashboard
                </Nav.Link>
                <Nav.Link
                    as={Link}
                    to="/dashboard/addTeamMember"
                    className={`mb-2 sidebar-link ${isActive('/dashboard') ? 'active-link' : ''}`}
                >
                    <FaTachometerAlt className="me-2" /> addTeamMember
                </Nav.Link>
                <Nav.Link
                    as={Link}
                    to="/dashboard"
                    className={`mb-2 sidebar-link ${isActive('/dashboard') ? 'active-link' : ''}`}
                >
                    <FaTachometerAlt className="me-2" /> hi
                </Nav.Link>

                {/* Add other sidebar links here */}
            </Nav>
        </div>
    );
};

export default Sidebar;
