
import React from "react";
import "./Banner.css"; // Custom CSS for background and overlay styling
import { Link } from 'react-router-dom';
import Typewriter from 'typewriter-effect';
const Banner = () => {
  return (
    <div className="hero-section-container">
      <div className="hero-section-container">
        <div className="text-center-container">
          <h6 className="text-uppercase text-white">
            {/* Technology meets original vision */}
          </h6>

          <h1 className="display-4  hh text-3xl">
            <Typewriter
              options={{
                strings: ['We Transform Ideas Into Technology'],
                autoStart: true,
                loop: true,
              }}
            />
          </h1>

          {/* <h1 className="display-4  hh">We Transform Ideas Into Technology</h1> */}
          <p className="lead">
            {/* We deliver cutting-edge IT solutions for businesses globally. */}
          </p>
          <Link to="/contact" className="btn btnn-link mb-4">Contact Now</Link>
        </div>
      </div>


    </div>
  );
};

export default Banner;

















// import React from "react";
// import "../../css/Banner.css"; // Custom CSS for background and overlay styling
// import { FaCloud, FaCogs, FaLock, FaChartPie } from "react-icons/fa";

// const Banner = () => {
//   return (
//     <div className="hero-section-container">
//       <div className="text-center text-white p-5">
//         <h6 className="text-uppercase text-white">
//           Technology meets original vision
//         </h6>
//         <h1 className="display-4 hh">We transform ideas into technology</h1>
//         <p className="lead">
//           We deliver cutting-edge IT solutions for businesses globally.
//         </p>
//         <button className="btn btnn-link mb-4">Read More</button>
//       </div>

//       {/* Card Container */}
//       <div className="cards-container">
//         <div className="card shadow-sm text-center card-rounded">
//           <div className="card-body">
//             <FaCogs className="card-icon mb-3" />
//             <h5 className="card-title">Infrastructure Technology</h5>
//             <p className="card-text">
//               Accelerate innovation with world-class tech.
//             </p>
//             <button className="btn btn-link">Read More</button>
//           </div>
//         </div>

//         <div className="card shadow-sm text-center card-rounded">
//           <div className="card-body">
//             <FaChartPie className="card-icon mb-3" />
//             <h5 className="card-title">IT Consultancy & Solution</h5>
//             <p className="card-text">
//               Achieve goals faster with expert advice.
//             </p>
//             <button className="btn btn-link">Read More</button>
//           </div>
//         </div>

//         <div className="card shadow-sm text-center card-rounded">
//           <div className="card-body">
//             <FaCloud className="card-icon mb-3" />
//             <h5 className="card-title">Cloud Managed Services</h5>
//             <p className="card-text">
//               Accelerate innovation with world-class tech.
//             </p>
//             <button className="btn btn-link">Read More</button>
//           </div>
//         </div>

//         <div className="card shadow-sm text-center card-rounded">
//           <div className="card-body">
//             <FaLock className="card-icon mb-3" />
//             <h5 className="card-title">Blockchain Technology</h5>
//             <p className="card-text">Secure solutions for modern challenges.</p>
//             <button className="btn btn-link">Read More</button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Banner;

