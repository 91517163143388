import React from "react";
import Navbar from "../Shared/Navbar";
import Footer from "../Shared/Footer";
import './career.css'

const CareerApplicationForm = () => {
    const jobs = [
        {
            careerTitle: "Software Engineer",
            careerDetails: {
                vacancy: "2",
                salary: "Negotiable",
                location: "Chattogram, Bangladesh",
                description: "Develop and maintain software solutions.",
                jobResponsibilities: "Writing clean, scalable code.",
                educationRequired: "Bachelor's in Computer Science or equivalent.",
                experienceRequired: ["2+ years of relevant experience."],
                additionalRequirements: ["Strong communication skills."],
                compensationBenefits: ["Health insurance", "Yearly bonuses"],
                ageRequirement: "25-35 years",
                deadline: "2024-12-31",
            },
        },
        {
            careerTitle: "UI/UX Designer",
            careerDetails: {
                vacancy: "1",
                salary: "30,000 BDT - 50,000 BDT",
                location: "Dhaka, Bangladesh",
                description: "Design intuitive user interfaces and experiences.",
                jobResponsibilities: "Create wireframes, prototypes, and user flows.",
                educationRequired: "Bachelor's in Design or related field.",
                experienceRequired: ["1+ years of experience in UI/UX design."],
                additionalRequirements: ["Proficiency in Figma or Adobe XD."],
                compensationBenefits: ["Flexible work hours", "Team outings"],
                ageRequirement: "22-30 years",
                deadline: "2024-12-15",
            },
        },
    ];

    const job = jobs[0];

    return (
        <div>
            <Navbar />
            <div className="main_section row m-5">
                <div className="col-lg-6 col-md-12 px-5" style={{ maxHeight: "90vh", overflowY: "scroll", scrollbarWidth: "thin" }}>

                    <h5 className="card-title fs-3 mb-4" style={{
                        background: "linear-gradient(90deg, #941BFE, #977EFE)",
                        WebkitBackgroundClip: "text",
                        color: "transparent"
                    }}>
                        {job.careerTitle}
                    </h5>


                    {job.careerDetails && (
                        <>
                            <p>Vacancy: {job.careerDetails.vacancy || "N/A"}</p>
                            <p>Salary: {job.careerDetails.salary || "Negotiable"}</p>
                            <p>Job Location: {job.careerDetails.location || "N/A"}</p>
                            <h5 className="mt-5">Description</h5>
                            <p>{job.careerDetails.description || "N/A"}</p>
                            <h5 className="mt-5 mb-3">Job Responsibilities</h5>
                            <p>{job.careerDetails.jobResponsibilities || "N/A"}</p>
                            <h5>Education Required</h5>
                            <p>{job.careerDetails.educationRequired || "N/A"}</p>
                            <h5>Experience Required</h5>
                            <ul>
                                {Array.isArray(job.careerDetails.experienceRequired) &&
                                    job.careerDetails.experienceRequired.length > 0 ? (
                                    job.careerDetails.experienceRequired.map((exp, idx) => <li key={idx}>{exp}</li>)
                                ) : <li>N/A</li>}
                            </ul>
                            <h5>Additional Requirements</h5>
                            <ul>
                                {Array.isArray(job.careerDetails.additionalRequirements) &&
                                    job.careerDetails.additionalRequirements.length > 0 ? (
                                    job.careerDetails.additionalRequirements.map((req, idx) => <li key={idx}>{req}</li>)
                                ) : <li>N/A</li>}
                            </ul>
                            <h5>Compensation & Other Benefits</h5>
                            <ul>
                                {Array.isArray(job.careerDetails.compensationBenefits) &&
                                    job.careerDetails.compensationBenefits.length > 0 ? (
                                    job.careerDetails.compensationBenefits.map((benefit, idx) => <li key={idx}>{benefit}</li>)
                                ) : <li>N/A</li>}
                            </ul>
                            <h5>Age Requirement</h5>
                            <p>{job.careerDetails.ageRequirement || "N/A"}</p>
                            <h5>Application Deadline</h5>
                            <p>{job.careerDetails.deadline ? new Date(job.careerDetails.deadline).toLocaleDateString() : "N/A"}</p>
                        </>
                    )}
                </div>

                <div className="col-lg-6 col-md-12 p-5 bg-light rounded shadow-sm" style={{ position: "sticky", top: "10px" }}>
                <h5 className="card-title fs-3 mb-4" style={{
                        background: "linear-gradient(90deg, #941BFE, #977EFE)",
                        WebkitBackgroundClip: "text",
                        color: "transparent"
                    }}>
                       Apply For This {job.careerTitle}
                    </h5>
                    
                    <form className="needs-validation" noValidate>
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">Name</label>
                            <input type="text" className="form-control" id="name" placeholder="Enter your name" required />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">Email</label>
                            <input type="email" className="form-control" id="email" placeholder="Enter your email" required />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="phone" className="form-label">Phone Number</label>
                            <input type="tel" className="form-control" id="phone" placeholder="Enter your phone number" required />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="photo" className="form-label">Upload Photo</label>
                            <input type="file" className="form-control" id="photo" accept="image/*" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="resume" className="form-label">Upload Resume</label>
                            <input type="file" className="form-control" id="resume" accept="application/pdf,image/*" required />
                        </div>
                        <button type="submit" className="btn buttonStyle">Submit Application</button>
                    </form>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default CareerApplicationForm;
