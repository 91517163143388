import React from 'react';

const RecentProjects = () => {
    return (
        <div>
          
            <div class="details">
              <div class="recentOrders">
                <div class="cardHeader pb-3 d-flex justify-content-between align-items-center ">
                <h4 className="text-primary fs-5">Recent Orders</h4>
                <button className='btn btn-sm btn-danger'>view all</button>
              </div>
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">Name</th>
                      <th scope="col">Price</th>
                      <th scope="col">Payment</th>
                      <th scope="col">Status</th>
                      <th scope="col">Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className=" bg-white ">
                      <td>AVINIL202302</td>
                      <td>Online college website</td>
                      <td className="text-success">16000</td>
                      <td className="text-primary">Paid</td>
                      <td className="text-danger">Completed</td>
                      <td className="btn btn-dark text-white bg-dark btn-sm m-1">
                        Details
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          
        </div>
    );
};

export default RecentProjects;