import React from "react";
// import profit from "../../asset/Images/dashboard/spending.png";
// import earns from "../../asset/Images/dashboard/salary.png";
// import spend from "../../asset/Images/dashboard/save-money.png";
// import employee from "../../asset/Images/dashboard/programmer.png";
// import profile from "../../asset/Images/dashboard/profile.png";
import Analytics from "./Analytics";
import RecentCustomers from "./RecentCustomers";
import RecentProjects from "./RecentProjects";
import { FaUsers, FaDollarSign, FaMoneyBillWave, FaChartLine, FaProjectDiagram, FaCheckCircle } from 'react-icons/fa'; // Import icons


const Overview = () => {
  return (
    <div>
      {/* overview top card start here  */}
      <div>
      <div className="cardBox d-flex justify-content-between">
      {/* Employee Card */}
      <div className="card text-center w-25 mx-3 p-3 bg-primary text-white border-0 rounded">
        <div className="iconBx">
          <FaUsers style={{ width: "40px", height: "30px" }} />
        </div>
        <div>
          <div className="numbers pt-2">80</div>
          <div className="cardName pb-0  mb-0">Employee</div>
        </div>
      </div>

      {/* Earning Card */}
      <div className="card text-center w-25 mx-3 p-3 bg-warning text-white border-0 rounded">
        <div className="iconBx">
          <FaDollarSign style={{ width: "40px", height: "30px" }} />
        </div>
        <div>
          <div className="numbers pt-2">284</div>
          <div className="cardName pb-0  mb-0">Earning</div>
        </div>
      </div>

      {/* Spend Card */}
      <div className="card text-center w-25 me-3 p-3 bg-danger text-white border-0 rounded">
        <div className="iconBx">
          <FaMoneyBillWave style={{ width: "40px", height: "30px" }} />
        </div>
        <div>
          <div className="numbers pt-2">1,504</div>
          <div className="cardName pb-0  mb-0">Spend</div>
        </div>
      </div>

      {/* Profit Card */}
      <div className="card text-center w-25 ms-3 p-3 bg-success text-white border-0 rounded">
        <div className="iconBx">
          <FaChartLine style={{ width: "40px", height: "30px" }} />
        </div>
        <div>
          <div className="numbers pt-2">$7,842</div>
          <div className="cardName pb-0  mb-0">Profit</div>
        </div>
      </div>

      {/* Pending Projects Card */}
      <div className="card text-center w-25 ms-3 p-3 bg-info text-white border-0 rounded">
        <div className="iconBx">
          <FaProjectDiagram style={{ width: "40px", height: "30px" }} />
        </div>
        <div>
          <div className="numbers pt-2">12</div>
          <div className="cardName pb-0  mb-0">Pending Projects</div>
        </div>
      </div>

      {/* Complete Projects Card */}
      <div className="card text-center w-25 ms-3 p-3 bg-secondary text-white border-0 rounded">
        <div className="iconBx">
          <FaCheckCircle style={{ width: "40px", height: "30px" }} />
        </div>
        <div>
          <div className="numbers pt-2">50</div>
          <div className="cardName pb-0  mb-0">Complete Projects</div>
        </div>
      </div>
    </div>





        {/* overview top card end  here  */}






        <hr />
        <div className="my-5 mx-3">
          <Analytics></Analytics>
        </div>

        <hr />






        {/* recent projects details */}
        <div className="d-flex mt-5">
          <div className=" w-75 mx-3">
            <RecentProjects></RecentProjects>
          </div>
          <div
            class="d-flex"
            Style="height: 200px; width:3px"
            className=" bg-dark"
          >
            <div class="vr"></div>
          </div>









          <div className="w-25 ps-3 ">
            {/* <!--   === New Customers   == --> */}
            <RecentCustomers></RecentCustomers>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
