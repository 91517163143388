import React from 'react';
import Navbar from '../Shared/Navbar';
import Footer from '../Shared/Footer';
import { Container, Row, Col, ProgressBar, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import friends1 from '../../asset/Images/friends3.jpg'
import WhyChoose from './WhyChooseUs';

const About = () => {
    return (
        <div className="">
            <Navbar></Navbar>
            <section class="banner-section">
                <div class="banner-overlay">
                    <h1 class="banner-title">About Us</h1>
                </div>
            </section>
            <WhyChoose></WhyChoose>









            {/*part 3 start*/}
            <Container className="py-5" style={{ backgroundColor: "white" }}>
                <Row>
                    {/* Left Section */}
                    <Col lg={6}>
                        <h1 className="mb-4" style={{ fontWeight: "bold" }}>  Preparing for your success,  <br /> we provide truly prominent <br /> IT solutions</h1>
                        <ul className="list-unstyled" style={{ fontSize: "1.1rem" }}>
                            <li className="mb-2">
                                <i className="bi bi-check-circle-fill me-2" style={{ color: "#6f42c1" }} ></i> We specialize in technological and IT-related services
                            </li>
                            <li className="mb-2">
                                <i className="bi bi-check-circle-fill me-2" style={{ color: "#6f42c1" }} ></i> Technological applications play a role
                            </li>
                            <li className="mb-2">
                                <i
                                    className="bi bi-check-circle-fill me-2"
                                    style={{ color: "#6f42c1" }}
                                ></i>
                                It’s better to achieve little than nothing at all
                            </li>
                        </ul>

                    </Col>

                    {/* Right Section */}
                    <Col lg={6}>
                        <p style={{ fontSize: "1.1rem" }}>
                            Accelerate innovation with world-class tech teams. We'll match
                            you to an entire remote team of incredible freelance talent for
                            all your software development needs: building cloud,
                            infrastructure, network, etc. We put a strong focus on the needs
                            of your business to figure out solutions that best fit your
                            demand and nail it.
                        </p>



                        {/* Progress Bars */}
                        <div className="mt-4">
                            <p style={{ fontWeight: "bold", color: "#6f42c1" }}>IT Management</p>
                            <ProgressBar
                                now={80}
                                label="80%"
                                className="mb-3"
                                style={{
                                    height: "1rem",
                                    backgroundColor: "#d3d3d3", // Light gray background
                                    borderRadius: "5px", // Rounded corners
                                    position: "relative", // Ensure the gradient overlay aligns correctly
                                }}
                            >
                                <div
                                    style={{
                                        width: "80%", // Match the progress percentage
                                        height: "100%",
                                        background: "linear-gradient(90deg, #941BFE, #977EFE)", // Gradient color
                                        borderRadius: "5px", // Match the border-radius
                                        position: "absolute", // Overlay the gradient
                                        top: 0,
                                        left: 0,
                                    }}
                                />
                            </ProgressBar>

                            <p style={{ fontWeight: "bold", color: "#6f42c1" }}>Data Security</p>
                            <ProgressBar
                                now={95}
                                label="95%"
                                className="mb-3"
                                style={{
                                    height: "1rem",
                                    backgroundColor: "#d3d3d3",
                                    borderRadius: "5px",
                                    position: "relative",
                                }}
                            >
                                <div
                                    style={{
                                        width: "95%",
                                        height: "100%",
                                        background: "linear-gradient(90deg, #941BFE, #977EFE)", // Gradient color
                                        borderRadius: "5px",
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                    }}
                                />
                            </ProgressBar>

                            <p style={{ fontWeight: "bold", color: "#6f42c1" }}>
                                Information Technology
                            </p>
                            <ProgressBar
                                now={80}
                                label="80%"
                                style={{
                                    height: "1rem",
                                    backgroundColor: "#d3d3d3",
                                    borderRadius: "5px",
                                    position: "relative",
                                }}
                            >
                                <div
                                    style={{
                                        width: "80%",
                                        height: "100%",
                                        background: "linear-gradient(90deg, #941BFE, #977EFE)", // Gradient color
                                        borderRadius: "5px",
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                    }}
                                />
                            </ProgressBar>
                        </div>



                    </Col>
                </Row>
            </Container>

            {/*part 3 end*/}








































            {/* <div className="container-fluid bg-white h-50">
                <img className='w-100 no-repeat bg-secondary bg-opacity-10' src={team} alt="" />
            </div> */}
            {/* 
            <div className="text-center wow fadeInUp pt-5" data-wow-delay="0.1s">
                <h6 className="bg-white text-center text-primary px-3">About Us</h6>
                <h1 className="mb-5">Learn About Us</h1>
            </div>

            {/* Our Identity Section }
            <div data-aos-duration="1000" data-aos-offset="300" data-aos="zoom-in-up">
                <div className="container">
                    <h2>Who <span className='text-danger fw-bolder fs-1'>We Are</span></h2>
                    <div>
                        <p className='text-wrap fs-4'>
                            We are a dedicated team of professionals committed to delivering exceptional solutions. Our expertise spans across various domains, enabling us to tackle diverse challenges with innovative approaches. We take pride in our ability to transform complex problems into simple, effective solutions. Our approach is centered on understanding your unique needs and delivering results that exceed expectations.
                        </p>
                        <div>
                            <div className="fs-4">
                                <div className="row g-5">
                                    <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
                                        <p>
                                            We have extensive experience in various fields, providing tailored solutions that fit the unique needs of our clients. Our team is committed to understanding your challenges and delivering high-quality results that drive success. Our process involves detailed analysis, strategic planning, and execution with a focus on achieving your goals.
                                        </p>
                                    </div>
                                    <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s" style={{ minHeight: '400px' }}>
                                        <div className="position-relative h-100">
                                            <img className="img-fluid w-100" src={friends1} alt="" style={{ objectFit: 'cover' }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Our Mission Section}
            <div data-aos-duration="1000" data-aos-offset="300" data-aos="zoom-in-up" className="py-5">
                <div className="container">
                    <h2>Our <span className='text-danger fw-bolder fs-1'>Mission</span></h2>
                    <div>
                        <ul className='fs-4'>
                            <li>Our mission is to empower businesses with cutting-edge technology and strategic insights. We strive to provide solutions that not only meet but exceed industry standards. Our focus is on fostering long-term relationships with our clients by delivering high-quality services that drive growth and success.</li>
                            <li>We are passionate about innovation and committed to making a positive impact through our work. By continuously improving our processes and adapting to new trends, we ensure that our solutions remain relevant and effective.</li>
                            <li>Our team is dedicated to delivering results that are both impactful and sustainable. We approach each project with a commitment to excellence, aiming to exceed client expectations and deliver exceptional value.</li>
                            <li>We believe in a collaborative approach, working closely with our clients to understand their unique needs and challenges. This allows us to provide tailored solutions that align with their goals and drive long-term success.</li>
                        </ul>
                    </div>
                </div>
            </div> 



 {/* 
image size
width 494
height 505

*/}

            {/* <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        <h6 className="   bg-white text-center text-primary px-3">Team</h6>
                        <h1 className="mb-5">Our Experties Member</h1>
                    </div>


                    <div data-aos-duration="1000" data-aos-offset="300" data-aos="fade-up" className="row g-4">


                        <div className="col-lg-3 col-md-6 wow fadeInUp " data-wow-delay="0.3s">
                            <div className="team-item bg-light  ">
                                <div className="overflow-hidden ">
                                    <img className="img-fluid " src={ceo} alt="" />
                                </div>
                                <div className="position-relative text-center p-4">
                                    <h5 className="mb-0">Avishek Devnath</h5>
                                    <small>Co-Founder & CEO</small>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-3 col-md-6 wow fadeInUp " data-wow-delay="0.3s">
                            <div className="team-item bg-light ">
                                <div className="overflow-hidden ">
                                    <img className="img-fluid " src={cto} alt="" />
                                </div>
                                <div className="text-center p-4">
                                    <h5 className="mb-0">Niloy Dey</h5>
                                    <small>Co-Founder & CTO </small>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 wow fadeInUp " data-wow-delay="0.3s">
                            <div className="team-item bg-light ">
                                <div className="overflow-hidden ">
                                    <img className="img-fluid " src={bappy} alt="" />
                                </div>
                                <div className="text-center p-4">
                                    <h5 className="mb-0">Bappy Islam Kakon</h5>
                                    <small>Law Advisor</small>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 wow fadeInUp " data-wow-delay="0.3s">
                            <div className="team-item bg-light  ">
                                <div className="overflow-hidden ">
                                    <img className="img-fluid " src={hr} alt="" />
                                </div>
                                <div className="position-relative text-center p-4">
                                    <h5 className="mb-0">Kaniz Fatema Roxy</h5>
                                    <small>HR Manager</small>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                            <div className="team-item bg-light">
                                <div className="overflow-hidden">
                                    <img className="img-fluid" src={arpita} alt="" />
                                </div>
                                <div className="text-center p-4">
                                    <h5 className="mb-0">Arpita Dhar </h5>
                                    <small>Brand Ambassador</small>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="team-item bg-light">
                                <div className="overflow-hidden">
                                    <img className="img-fluid" src={analyst} alt="" />
                                </div>
                                <div className="text-center p-4">
                                    <h5 className="mb-0">Suparna Dhar </h5>
                                    <small>IT Analyst</small>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                            <div className="team-item bg-light">
                                <div className="overflow-hidden">
                                    <img className="img-fluid" src={softwareDeveloper} alt="" />
                                </div>
                                <div className="text-center p-4">
                                    <h5 className="mb-0">Bijon Talukder</h5>
                                    <small>Android Developer</small>
                                </div>
                            </div>
                        </div>

         

                        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                            <div className="team-item bg-light">
                                <div className="overflow-hidden">
                                    <img className="img-fluid " src={trishna} alt="" />
                                </div>
                                <div className="text-center p-4">
                                    <h5 className="mb-0">Trishna Nath</h5>
                                    <small>Front-End Developer</small>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-3 col-md-6 wow fadeInUp " data-wow-delay="0.3s">
                            <div className="team-item bg-light  ">
                                <div className="overflow-hidden ">
                                    <img className="img-fluid " src={priom} alt="" />
                                </div>
                                <div className="position-relative text-center p-4">
                                    <h5 className="mb-0">Priom Prasad</h5>
                                    <small>.Net Developer</small>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                            <div className="team-item bg-light">
                                <div className="overflow-hidden">
                                    <img className="img-fluid" src={ritu} alt="" />
                                </div>
                                <div className="text-center p-4">
                                    <h5 className="mb-0">Ritu Priya Chy</h5>
                                    <small>Software Developer</small>
                                </div>
                            </div>
                        </div>



                        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                            <div className="team-item bg-light">
                                <div className="overflow-hidden">
                                    <img className="img-fluid" src={dip} alt="" />
                                </div>
                               
                                <div className="text-center p-4">
                                    <h5 className="mb-0">Diprodip Das</h5>
                                    <small>Web Developer</small>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                            <div className="team-item bg-light">
                                <div className="overflow-hidden">
                                    <img className="img-fluid" src={sabid} alt="" />
                                </div>
                                <div className="text-center p-4">
                                    <h5 className="mb-0">Sabid Jidan </h5>
                                    <small>Software Developer</small>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div> */}

            <Footer></Footer>
        </div>
    );
};

export default About;




/* <div className="position-relative d-flex justify-content-center" Style="margin-top: -23px;">
                                    <div className="bg-light d-flex justify-content-center pt-2 px-1">
                                        <a className="btn btn-sm-square btn-primary      mx-1" Style="background: #2EB67D" href=""><i className="fab fa-facebook-f"></i></a>
                                        <a className="btn btn-sm-square btn-primary bg-danger    mx-1"  href=""><i className="fab fa-twitter"></i></a>
                                        <a className="btn btn-sm-square btn-primary     mx-1" href="" Style="backgroumd: #E5AD2D"><i className="fab fa-instagram"></i></a>
                                    </div>
                                </div> 
*/