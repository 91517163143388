import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaHandshake, FaLightbulb, FaShieldAlt, FaUsers } from "react-icons/fa";

function OurValues() {
  const values = [
    {
      icon: <FaHandshake size={50} style={{ color: "#6f42c1" }} />,
      title: "Integrity",
      description:
        "We uphold the highest standards of honesty and transparency.",
    },
    {
      icon: <FaLightbulb size={50} style={{ color: "#6f42c1" }} />,
      title: "Innovation",
      description: "We drive creative solutions and continuously improve.",
    },
    {
      icon: <FaShieldAlt size={50} style={{ color: "#6f42c1" }} />,
      title: "Security",
      description:
        "Protecting your data and ensuring confidentiality is our priority.",
    },
    {
      icon: <FaUsers size={50} style={{ color: "#6f42c1" }} />,
      title: "Teamwork",
      description: "Collaboration is at the heart of everything we do.",
    },
  ];







  return (
    <Container className="py-5">
      <h2
  className="text-center mb-2"
  style={{
    fontWeight: "bold",
    background: "linear-gradient(90deg, #941BFE, #977EFE)", // Gradient color
    WebkitBackgroundClip: "text", // Clip the gradient to the text
    WebkitTextFillColor: "transparent", // Make the text transparent to show the gradient
    color: "transparent", // Fallback for browsers not supporting WebKit
  }}
>
  Our Values
</h2>

      <div
        className="mx-auto mb-4"
        style={{
          width: "60px",
          height: "4px",
          backgroundColor: "#6f42c1",
          borderRadius: "2px",
        }}
      ></div>
      



      <Row>
  {values.map((value, index) => (
    <Col lg={3} md={6} sm={12} className="mb-4" key={index}>
      <div
        className="text-center p-4"
        style={{
          backgroundColor: "#e9ecef", // Slightly darker gray background
          borderRadius: "10px",
          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
          transition: "transform 0.2s, box-shadow 0.2s",
        }}
        onMouseEnter={(e) =>
          (e.currentTarget.style.boxShadow =
            "0 8px 15px rgba(0, 0, 0, 0.2)")
        }
        onMouseLeave={(e) =>
          (e.currentTarget.style.boxShadow =
            "0 2px 5px rgba(0, 0, 0, 0.1)")
        }
      >
        {/* Icon with Linear Gradient */}
        <div
          className="mb-3"
          style={{
            fontSize: "2rem", // Adjust icon size as needed
            background: "linear-gradient(90deg, #941BFE, #977EFE)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            color: "transparent", // Fallback for unsupported browsers
            display: "inline-block",
          }}
        >
          {value.icon}
        </div>

        {/* Heading with Linear Gradient */}
        <h5
          className="mb-2"
          style={{
            fontWeight: "bold",
            background: "linear-gradient(90deg, #941BFE, #977EFE)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            color: "transparent", // Fallback for unsupported browsers
          }}
        >
          {value.title}
        </h5>

        {/* Description */}
        <p style={{ color: "#6c757d", fontSize: "0.95rem" }}>
          {value.description}
        </p>
      </div>
    </Col>
  ))}
</Row>

    </Container>
  );
}

export default OurValues;
