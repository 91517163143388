import React from "react";
import CountUp from "react-countup";
import "bootstrap/dist/css/bootstrap.min.css";
import "./stat.css"; 

const StatsSection = () => {
  const stats = [
    { icon: "bi bi-emoji-smile", count: 1789, label: "Happy Clients" },
    { icon: "bi bi-lightbulb", count: 491, label: "Finished Projects" },
    { icon: "bi bi-person", count: 245, label: "Skilled Experts" },
    { icon: "bi bi-box", count: 1090, label: "Media Posts" },
  ];

  return (
    <div className="container d-flex justify-content-around stats-section">
      {stats.map((stat, index) => (
        <div key={index} className="stat-card text-center">
          <h2 className="stat-count">
            <i className={`${stat.icon} stat-icon`} aria-hidden="true"></i>
            <CountUp end={stat.count} duration={2} />+
          </h2>
          <p className="stat-label">{stat.label.toUpperCase()}</p>
        </div>
      ))}
    </div>
  );
};

export default StatsSection;
