import React from 'react';
import Navbar from '../Shared/Navbar';
import Footer from '../Shared/Footer';
import career from '../../asset/Images/banner/carrer.png'
import OurValues from './ourvalues';
import "./career.css";
import JobOpenings from './JobOpenings';
const Career = () => {
  return (
    <div>
      <Navbar></Navbar>


      <section class="banner-section">
        <div class="banner-overlay">
          <h1 class="banner-title">Career</h1>
        </div>
      </section>



      <OurValues></OurValues>



      <JobOpenings></JobOpenings>
      

      <Footer></Footer>
    </div>
  );
};

export default Career;