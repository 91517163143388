import { Outlet } from 'react-router-dom';
import Navbar from '../Shared/Navbar';
import './css/dashboard.css'; // Ensure this CSS is loaded
import Sidebar from './Sidebar';
import React, { useState, useEffect } from 'react';

const Dashboard = () => {
  return (
    <div className="dashboard-container">
      {/* Ensure all styling applies correctly */}
      <Navbar />
      <div className="dashboard-content d-flex">
        <Sidebar />
        <div className="content p-4 w-100">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
