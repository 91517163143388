import React from 'react';
import Footer from '../../Shared/Footer';
import Navbar from '../../Shared/Navbar';

const WebDevelopement = () => {
    return (
        <div>
            <Navbar></Navbar>
           
            hi web development
           <Footer></Footer>
            
        </div>
    );
};

export default WebDevelopement;