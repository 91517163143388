import React from 'react';
import logo from '../../asset/Images/Logo/avinil-it-5.png'; // Replace with the actual path to your logo file

const Loading = () => {
    return (
        <div>
            <div 
                id="spinner" 
                className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
            >
                {/* Custom loader using logo */}
                <div className="logo-loader">
                    <img src={logo} alt="Loading..." className="loading-logo" />
                </div>
            </div>

            {/* Inline CSS for animation */}
            <style jsx>{`
                .logo-loader {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 6rem;
                    height: 6rem;
                    animation: spin 2s linear infinite;
                }

                .loading-logo {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }

                @keyframes spin {
                    0% {
                        transform: rotate(0deg);
                    }
                    100% {
                        transform: rotate(360deg);
                    }
                }
            `}</style>
        </div>
    );
};

export default Loading;
