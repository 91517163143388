import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './sharedCss/navbar.css';
import avinil from '../../asset/Images/Logo/avinil-logo-final.png';

const Navbar = () => {
  const [isToggled, setIsToggled] = useState(false);
  const [isServicesDropdownOpen, setIsServicesDropdownOpen] = useState(false);

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  const closeNavbar = () => {
    setIsToggled(false);
  };

  const toggleServicesDropdown = () => {
    setIsServicesDropdownOpen(!isServicesDropdownOpen);
  };

  return (
    <div className="bg-white">
      <nav className="navbar navbar-expand-lg navbar-light sticky-top p-0">
        <div className="container navbarStyle">
          {/* Logo */}
          <NavLink to="/" className="navbar-brand d-flex align-items-center">
            <img className="img-fluid" src={avinil} alt="Avinil Logo" />
          </NavLink>

          {/* Navbar Toggle Button for Mobile */}
          <button
            type="button"
            className="navbar-toggler"
            onClick={handleToggle}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* Navbar Links */}
          <div
            className={`navbar-collapse ${isToggled ? 'show-dropdown' : 'hide-dropdown'}`}
          >
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <NavLink
                  to="/home"
                  className="nav-link"
                  activeClassName="active-route"
                  exact
                  onClick={closeNavbar}
                >
                  Home
                </NavLink>
              </li>







              {/* Services Dropdown */}
<li className="nav-item dropdown">
  <button
    className="nav-link"
    onClick={toggleServicesDropdown}
  >
    Services
  </button>

  {/* Dropdown Menu */}
  <div className={`dropdown-menu ${isServicesDropdownOpen ? 'show' : ''}`}>
    <div className="dropdown-columns">
      {/* Development Services */}
      <div className="dropdown-column">
        <NavLink to="/services/web-development" className="dropdown-item" onClick={closeNavbar}>
          Web Development
        </NavLink>
        <NavLink to="/services/app-development" className="dropdown-item" onClick={closeNavbar}>
          App Development
        </NavLink>
        <NavLink to="/services/erp-solution" className="dropdown-item" onClick={closeNavbar}>
          ERP Solution
        </NavLink>
        <NavLink to="/services/digital-marketing" className="dropdown-item" onClick={closeNavbar}>
          Digital Marketing
        </NavLink>
        <NavLink to="/services/e-commerce" className="dropdown-item" onClick={closeNavbar}>
          E-commerce Solution
        </NavLink>
        <NavLink to="/services/graphic-design" className="dropdown-item" onClick={closeNavbar}>
          Graphic Design
        </NavLink>
      </div>
    </div>
  </div>
</li>









              <li className="nav-item">
                <NavLink
                  to="/about"
                  className="nav-link"
                  activeClassName="active-route"
                  exact
                  onClick={closeNavbar}
                >
                  About
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/dashboard"
                  className="nav-link"
                  activeClassName="active-route"
                  exact
                  onClick={closeNavbar}
                >
                  Dashboard
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/career"
                  className="nav-link"
                  activeClassName="active-route"
                  exact
                  onClick={closeNavbar}
                >
                  Career
                </NavLink>
              </li>
            </ul>
            {/* Contact Button */}
            <NavLink
              to="/contact"
              className="buttonStyle contact-btn"
              activeClassName="active-route"
              exact
              onClick={closeNavbar}
            >
              Contact
            </NavLink>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
